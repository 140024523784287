/* Education.css */
.education {
  background-color: #f9f9f9;
  padding: 50px 0;
  text-align: center;
}

.education-container {
  max-width: 800px;
  margin: 0 auto;
}

.education-item {
  margin: 30px;
  text-align: left;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.education-item:hover{
  transform: scale(1.05);
}

.education h2 {
  color: #3498db;
}

.education h3 {
  color: #333;
}

.education p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .education-item {
    padding: 15px;
    /* Reduce padding on smaller screens */
  }
}