.about-me {
  background-color: #ecf0f1;
  padding-top: 90px;
  text-align: center;
  padding-bottom: 50px;
}

.about-me-container {
  max-width: 800px;
  margin: 0 auto;
}

.about-me-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}

.about-me-text {
  max-width: 500px;
  text-align: center;
  margin-bottom: 20px;
}

.about-me h2 {
  color: #3498db;
}

.about-me p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin: 30px;
}

.about-me-image img {
  max-width: 80%;
  border-radius: 50%;
  padding: 5px;
  margin-top: 150px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {
  .about-me-content {
    flex-direction: row;
    text-align: left;
  }

  .about-me-text {

    margin-bottom: 0;
  }

  .about-me-image img {
    max-width: 300px;
  }
}