/* Header.css */
.header {
  background-color: #3498db;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
}

.header-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
  padding-left: 10px;
}

.menu-icon {
  cursor: pointer;
  display: none;
  /* Hide the menu icon by default on larger screens */
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: background-color 0.3s ease-in-out;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu a {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.2em;
  transition: color 0.3s ease-in-out;
}

.nav-menu a:hover {
  color: #ffd700;
}




/* Styles for the hamburger menu on smaller screens */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
    padding-right: 10px;
  }

  .nav-menu {
    flex-direction: column;
    position: absolute;
    top: 70px;
    /* Adjust the top position based on your header height */
    left: 0;
    width: 100%;
    background-color: #3498db;
    text-align: center;
   
    display: none;
    /* Hide the menu by default */
  }

  .nav-menu.open {
    display: flex;
  }

  .bar.open1 {
    transform: rotate(-45deg) translate(-5px, 6px);
    
  }

  .bar.open2 {
    opacity: 0;
  }

  .bar.open3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}