/* Contact.css */
.contact {
  background-color: #ffffff;
  padding: 50px 0;
  text-align: center;
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
}

.contact h2 {
  color: #3498db;
}

.contact p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

.contact-info,
.social-media {
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.2em;
  color: #333;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Center icons vertically */
}

.social-media a {
  margin: 0 10px;
  font-size: 2em;
  /* Increase the icon size */
  color: #3498db;
  transition: color 0.3s ease-in-out;
}

.social-media a img {
  width: 40px;
  /* Set a fixed width for the icon image */
}

.social-media a:hover {
  color: #2980b9;
}

form {
  background-color: #3498db;
  /* Form background color */
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

label {
  display: block;
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 5px;
}

input,
textarea {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
}

button {
  background-color: #fff;
  color: #3498db;
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

button:hover {
  background-color: #2980b9;
  color: #fff;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .contact {
    padding: 50px 10px;
    /* Further adjust padding for smaller screens */
  }

  .social-media {
    flex-wrap: wrap;
    /* Allow social media icons to wrap on small screens */
  }

  .social-media a {
    margin: 10px 5px;
    /* Adjust margin for smaller screens */
  }
}