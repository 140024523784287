/* Services.css */
.services {
    background-color: #f9f9f9;
    padding: 50px 0;
    text-align: center;
}

.services-container {
    max-width: 800px;
    margin: 0 auto;
}

.service {
    margin: 30px;
    display: flex;
    flex-direction: column;
    /* Stack image on top of text on small screens */
    align-items: center;
    text-align: left;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.services h2 {
    color: #3498db;
}

.service:hover {
    transform: scale(1.05);
}

.service.expanded {
    height: auto;
}

.service-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
    /* Add some spacing between image and text on small screens */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.service-text {
    max-width: 500px;
}

.service h3 {
    color: #3498db;
}

.service p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
    .service {
        flex-direction: row;
        /* Display side by side on larger screens */
        align-items: center;
    }

    .service-image {
        margin-bottom: 0;
        margin-right: 20px;

    }
}